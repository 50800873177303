<template>
  <div class="exchangeCode">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">领取兑换</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="bindTitle">输入兑换码</div>
      <div class="tip">每个兑换码只能输入一次</div>
      <div class="inputBox">
        <van-field
          class="exchangeCodeInput"
          v-model="exchangeCode"
          :border="false"
          label="兑换码"
          placeholder="请输入兑换码 (字母大写)"
          @blur="changBlur"
        />
      </div>
      <div class="groupTip">官方社群领取更多福利</div>
      <div class="exchangeBtn" @click="exchangeCodde">立即兑换</div>
      <!-- <van-sticky v-if="list.length > 0">
                <div class="trBox">
                    <div class="th">兑换码</div>
                    <div class="th">兑换类型</div>
                    <div class="th">兑换时间</div>
                </div>
            </van-sticky>
            <div class="logItem" v-for="item in list" :key="item.id">
                <div class="itemText white">{{item.code}}</div>
                <div class="itemText">{{item.typeDesc}}</div>
                <div class="itemText">{{item.exDate}}</div>
            </div> -->
      <!-- <van-sticky>
                <div class="trBox">
                    <div class="th">兑换码</div>
                    <div class="th">兑换类型</div>
                    <div class="th">兑换时间</div>
                </div>
            </van-sticky>
            <div class="logItem" v-for="item in 100" :key="item">
                <div class="itemText black">DM56GH</div>
                <div class="itemText">10天会员</div>
                <div class="itemText">2022.05.04</div>
            </div> -->
    </div>
  </div>
</template>

<script>
import { Field, Loading, Toast, Sticky } from "vant";
import { exchange, queryExchangeLog } from "@/api/mine";
export default {
  components: {
    [Field.name]: Field,
    [Sticky.name]: Sticky,
    // [Loading.name]: Loading,
  },
  data() {
    return {
      container: null,
      exchangeCode: "",
      list: [],
    };
  },
  created() {
    // this.getList();
  },
  methods: {
    changBlur() {
      window.scroll(0, 0); //失焦后强制让页面归位
    },
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    async getList() {
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(queryExchangeLog);
        this.$store.commit("app/SET_LOADING", false);
        if (res.code === 200) {
          this.list = res.data.list;
        } else {
          Toast(res.tip || "查询失败");
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("查询失败");
      }
    },
    // 绑定邀请码
    async exchangeCodde() {
      if (!this.exchangeCode) {
        Toast("请填写兑换码");
        return;
      }

      let req = {
        code: this.exchangeCode,
      };
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(exchange, req);
        this.$store.commit("app/SET_LOADING", false);
        if (res.code === 200) {
          this.getList();
          Toast("兑换成功");
        } else {
          Toast(res.tip || "兑换失败，请稍后再试");
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("操作失败");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.exchangeCode {
  height: 100%;
  overflow: hidden;
  background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 18px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }
  /deep/ .van-sticky--fixed {
    top: 44px;
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 0 16px;
    box-sizing: border-box;

    .bindTitle {
      margin-top: 36px;
      font-size: 16px;
      font-weight: 900;
      color: #333;
      font-family: "Dream Han Sans TC";
      line-height: 22px;
    }

    .tip {
      font-size: 16px;
      margin-top: 12px;
      color: #666;
      font-family: "PingFang SC";
      font-weight: 500;
      line-height: 22px;
      // padding: 0 12px;
      // box-sizing: border-box;
    }
    .inputBox {
      // padding: 0 12px;
      // box-sizing: border-box;
    }

    .exchangeCodeInput {
      // margin: 70px 12px 0;
      width: 323px;
      height: 36px;
      //   padding: 10px 8px;
      margin-top: 32px;
      //   box-sizing: border-box;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.05);
      background: #f5f5f5;
      box-shadow: 2px 2px 4px 0px rgba(174, 174, 192, 0.2) inset,
        -5px -5px 4px 0px rgba(255, 255, 255, 0.3) inset;
      display: flex;
      align-items: center;
      justify-content: center;

      /deep/ .van-field__label {
        width: 42px;
        margin-right: 12px;
        font-size: 14px;
        font-weight: 500;
        color: #333;
        display: flex;
        align-items: center;
        font-family: "PingFang SC";
      }

      /deep/ .van-field__control {
        font-size: 12px;
        color: #000;
      }

      ::placeholder {
        color: #999999;
        font-size: 12px;
        font-family: "PingFang SC";
        line-height: 18px;
      }
    }
  }
  .groupTip {
    font-size: 12px;
    color: #999;
    margin-top: 18px;
  }

  .inviteTip {
    color: #716f86;
    font-size: 14px;
    margin-top: 10px;
  }

  .exchangeBtn {
    width: 333px;
    height: 56px;
    border-radius: 56px;
    line-height: 56px;
    text-align: center;
    font-size: 16px;
    font-family: "Dream Han Sans TC";
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    color: #b09fd9;
    background: #fff;
    position: fixed;
    left: 50%;
    bottom: 51px;
    transform: translateX(-50%);
    border-radius: 30px;
    box-shadow: -5px -5px 20px 0px #fff,
      5px 5px 20px 0px rgba(174, 174, 192, 0.5);
    // background: #abaaba;
  }

  .isDisable {
    background: #abaaba;
  }

  .trBox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // grid-row-gap: 6px;
    // grid-column-gap: px;
    .th {
      width: 123px;
      height: 44px;
      line-height: 44px;
      font-size: 15px;
      color: #000;
      text-align: center;
      background: #e6e6e6;
    }
  }
  .logItem {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // grid-row-gap: 6px;
    // grid-column-gap: 6px;
    .itemText {
      width: 123px;
      height: 48px;
      line-height: 48px;
      font-size: 15px;
      color: #9f9eb1;
      text-align: center;
      border-bottom: 1px solid #e6e6e6;
      // background: #1b164c;
    }
  }
  .black {
    color: #000 !important;
  }
}
</style>
